<template>
  <div />
</template>

<script>
import AUTH_TOKEN from '../constants/storage';

export default {
  data() {
    return {};
  },
  computed: {
    employeeReferral() {
      return this.$route.query.e || null;
    },
    flow() {
      return this.$route.params.id || undefined;
    },
    onDemand() {
      return !!this.$route.query.d;
    },

    pharmacyId() {
      return this.$route.query.p || null;
    },
    tenantUrl() {
      return this.$route.params.tenantUrl || null;
    },
    theme() {
      return this.$route.query.t || null;
    },
  },
  methods: {
    resetAuthToken() {
      if (localStorage.getItem(AUTH_TOKEN)) localStorage.removeItem(AUTH_TOKEN);
    },
  },
  async mounted() {
    this.resetAuthToken();
    await this.$store.dispatch('newRegistration');
    await this.$store.dispatch('registerInit', {
      tenantUrl: this.tenantUrl,
      flow: this.flow,
      theme: this.theme,
      onDemand: this.onDemand,
      pharmacyId: this.pharmacyId,
      employeeRef: this.employeeReferral,
    });

    this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
  },
};
</script>
